import React, { useEffect, useState } from "react"

// import Header from "./Header"

const useHorizontalPhoneAlert = (color = "#efefef") => {
  const warnTimeout = 1000

  const [
    beenWarnedAboutOrientationTime,
    setBeenWarnedAboutOrientationTime,
  ] = useState(0)

  useEffect(() => {
    const checkMobileOrientation = () => {
      const justBeenWarned =
        Date.now() - beenWarnedAboutOrientationTime < warnTimeout ? true : false
      if (justBeenWarned) return

      const { innerWidth, innerHeight } = window
      if (innerWidth < 900 && innerHeight < 500) {
        document.querySelector("body").style.visibility = "hidden"
        document.querySelector("body").style.opacity = "0"
        document.querySelector("html").style.backgroundColor = color
        setTimeout(() => {
          alert(
            "For best viewing experience, please view our website vertically"
          )
        }, 100)

        setBeenWarnedAboutOrientationTime(Date.now())
      } else {
        document.querySelector("html").style.backgroundColor = ""
        document.querySelector("body").style.visibility = "visible"
        document.querySelector("body").style.opacity = "1"
      }
    }
    checkMobileOrientation()

    window.addEventListener("orientationchange", () =>
      setTimeout(checkMobileOrientation, 500)
    )

    return () => {
      window.removeEventListener("orientationchange", () =>
        setTimeout(checkMobileOrientation, 500)
      )
    }
  }, [])
}

const Layout = ({ children }) => {
  useHorizontalPhoneAlert()
  return (
    <>
      {/* <Header /> */}
      <main>{children}</main>
    </>
  )
}

export default Layout
