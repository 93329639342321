import React, { useState, useEffect } from "react"

export const context = React.createContext()

const Provider = ({ children }) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    console.log(state)
  }, [state])

  const providerContext = {
    state,
    setState,
  }

  return <context.Provider value={providerContext}>{children}</context.Provider>
}

export default ({ element }) => <Provider>{element}</Provider>
